
//
// FAQ Accordian
//

Widgets['accordian'] = function FaqAccordian (host, config, $$) {

  // Setup
  var activeClass = 'is-open';

  // Dom
  var triggers = $$('[data-accordian-trigger]');

  // State
  var current = select(hasClass(activeClass), triggers.map(getParent));

  // Functions
  function open (target) {
    close(current);
    target.classList.add(activeClass);
    current = target;
  }

  function close (target) {
    if (!target) return;
    target.classList.remove(activeClass);
    current = null;
  }

  // Listeners
  triggers.forEach(function (trigger) {
    trigger.addEventListener('click', function () {
      open(this.parentNode);
    });
  });

  // Interface
  return {
    open:  open,
    close: close
  };
}

