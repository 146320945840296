
Widgets['slideshow'] = function Slideshow (host, config, $$) {
  var instance = new Flickity(host, {
    pageDots: true,
    wrapAround: true,
    imagesLoaded: true,
    prevNextButtons: false,
    autoPlay: 3000
  });
};

