
//
// Nav Overlay
//

Widgets['nav-overlay'] = function NavOverlay (host, config, $$) {

  // Setup
  var activeClass  = 'is-active';
  var overlayState = { open: false };

  // Dom
  var overlay  = $$('[data-nav-overlay]')[0];
  var triggers = $$('[data-menu-open-trigger]');

  // Functions
  function open () {
    overlay.classList.add(activeClass);
    overlayState.open = true;
    triggers.forEach(function (el) { el.textContent = 'Close'; });
  }

  function close () {
    overlay.classList.remove(activeClass);
    overlayState.open = false;
    triggers.forEach(function (el) { el.textContent = 'Menu'; });
  }

  // Listeners
  triggers.forEach(function (trigger) {
    trigger.addEventListener('click', function (el) {
      if (overlayState.open) { close(); } else { open(); }
    });
  });

  // Interface
  return {
    open: open,
    close: close
  };
};

