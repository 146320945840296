
//
// Dom Ready
//

document.addEventListener('DOMContentLoaded', function () {

  // Init Widgets
  selectDom('[data-widget]').reverse().forEach(function (widget) {
    widget.dataset.widget.split(' ').forEach(function (type) {
      if (Widgets[type]) {
        Widgets[type](widget, widget.config, findWithin(widget));
      } else if (type) {
        console.warn('Unknown widget:', type);
      }
    });
  });

  // Init Controllers
  selectDom('[data-controller]').reverse().forEach(function (dom) {
    dom.dataset.controller.split(' ').forEach(function (ctrl) {
      if (Controllers[ctrl]) {
        Controllers[ctrl](dom, findWithin(dom));
      } else if (ctrl) {
        console.warn('Unknown controller:', ctrl);
      }
    });
  });

  fitText(selectDom(".grid-box-text"), 300, {});

});


// End private scope
}());
