
//
// Helpers
//

function log () {
  console.log.apply(console, arguments);
  return arguments[0];
}

function toArray (xs) {
  return [].slice.apply(xs);
}

function selectDom (sel, parent) {
  if (!parent) parent = document;
  return toArray(parent.querySelectorAll(sel));
}

function childrenOf (el) {
  return toArray(el.children);
}

function select (λ, xs) {
  var r = xs.filter(λ);
  return r.length ? r[0] : null;
}

function getParent (el) {
  return el.parentNode;
}

function hasClass (x) {
  return function (el) {
    return el.classList.contains(x);
  };
}

function findWithin (dom) {
  return function _findWithin (sel) {
    return [].slice.apply(dom.querySelectorAll(sel));
  };
}

function defer (λ) {
  return setTimeout(λ, 0);
}

function delay (t, λ) {
  return setTimeout(λ, t * 1000);
}

