
//
// Basic Expander
//

Widgets['expander'] = function Expander (host, config, $$) {

  // Setup
  var activeClass = 'is-open';

  // Dom
  var triggers = $$('[data-expander-trigger]');

  // State
  var state = { open: host.classList.contains(activeClass) };

  // Functions
  function open () {
    state.open = true;
    host.classList.add(activeClass);
  }

  function close () {
    state.open = false;
    host.classList.remove(activeClass);
  }

  // Listeners
  triggers.forEach(function (trigger) {
    var normText = trigger.textContent;
    var altText  = trigger.dataset.altText;

    trigger.addEventListener('click', function () {
      if (state.open) {
        close();
        trigger.textContent = normText;
      } else {
        open();
        trigger.textContent = altText;
      }
    });
  });

  // Interface
  return {
    open:  open,
    close: close
  };
}

