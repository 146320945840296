
Widgets['wipe-banner'] = function WipeBanner (host, config, $$) {

  // Setup
  var revealClass = 'reveal';

  // Functions

  function next (now) {
    childrenOf(host).forEach(function (slide) {
      slide.classList.remove(revealClass);
    });

    if (now) {
      host.children[0].classList.add(revealClass);
      delay(1.5, function () { host.insertBefore(host.children[0], null); });


    } else {
      defer(function () {
        host.children[0].classList.add(revealClass);
        delay(1.5, function () { host.insertBefore(host.children[0], null); });
      });
    }
  }

  function resize () {
    var dim = host.getBoundingClientRect();

    childrenOf(host).forEach(function (slide) {
      selectDom('.wipe-banner-slide-side', slide).forEach(function (side) {
        side.children[0].style.width = dim.width/2 + 'px';
      });
    });
  }

  // Listeners
  window.addEventListener('resize', resize);

  // Init
  resize();

  // Autoadvance
  setInterval(next, 3000);

};

